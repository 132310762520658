import React from "react";
import { SeeAllStyledModal } from "./styles";
import { Modal } from "react-bootstrap";
import { CustomTitle } from "../../LeaderboardSection/style";
import PropTypes from "prop-types";
import { commaSeperator, convertMilesToKm, rankingDashboard } from "../../../utils/methods";
import { ImageUrl } from "../../../utils/constants";
import { Border } from "../../EventDetails/styles";
import { isNull } from "lodash";

const TeamInfo = (props) => {

  const fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  const SeeAllModal = () => {
    const { showTeamInfo, hideTeamInfoModal, selectedTeamDetails, activityType, DistanceUnit,teamPosition } = props;
    const {  name, slogan,team_contribution, team_users, logo } = selectedTeamDetails;
    return (
      <SeeAllStyledModal
        show={showTeamInfo}
        onHide={hideTeamInfoModal}
        dialogClassName="modal-90w"
        background={"#005C87"}
      >
        <Modal.Header>
          <CustomTitle>
            <div>
              <span>Team Info</span>
              <span className="closeImage" onClick={hideTeamInfoModal}>
                <img src="/public/images/new_close_icon.png" />
              </span>
            </div>
          </CustomTitle>
        </Modal.Header>

        <Modal.Body>
          {/* Overall Team Position */}
          <div
            style={{
              background:
                "#69c2ff",
              height: "50px",
              padding:'0px 15px',
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span
                style={{ color: "white", fontSize: "16px", lineHeight: "20px" }}
              >
                Overall Team Position
              </span>
            </div>
            <div style={{ color: "#FFF", fontSize: "16px", lineHeight: "20px", fontFamily:'Rubik-Medium' }}>
              {`${teamPosition + 1}${rankingDashboard(teamPosition)}`}
            </div>
          </div>

          <div style={{ display: "flex", width: "100%", padding: "30px" }}>
            <div>
              <img
                onError={fallBack}
                width={"150"}
                height={"150"}
                src={ logo? ImageUrl + "/" + logo : '/public/images/defaultProfileImg.png'}
                style={{ border: "1px solid #005C874D", borderRadius: "6px", backgroundColor:'white' }}
              />
            </div>
            <div style={{ marginLeft: "20px", width: "100%" }}>
              <div>
                <span
                  style={{
                    color: "#005C87",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  Team Name
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: "#005C87",
                    fontSize: "18px",
                    lineHeight: "20px",
                    fontFamily:"rubik-medium"
                  }}
                >
                  {name || "-"}
                </span>
              </div>
              <Border style={{ margin: "10px 0px 0px 0px" }} width="100%" background="#005C874D"/>

              <div style={{ marginTop: "10px" }}>
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#005C87",
                  }}
                >
                  Slogan
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: "#005C87",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: "600",
                  }}
                >
                  {slogan || "-"}
                </span>
              </div>
              <Border style={{ margin: "10px 0px 0px 0px" }} width="100%" background="#005C874D"/>

              <div style={{ marginTop: "10px" }}>
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#005C87",
                  }}
                >
                  Team Contribution
                </span>
              </div>
              <div>
                <span                   
                  style={{
                    color: "#005C87",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: "600",
                  }}>{isNull(team_contribution) ? 0 :activityType === 'distance'?DistanceUnit === "KILOMETER"?`${commaSeperator(convertMilesToKm(team_contribution))} ` :`${commaSeperator(team_contribution)} `: `${commaSeperator(team_contribution)} `}</span>
                <span 
                  style={{
                    color: "#005C87",
                    fontSize: "18px",
                    lineHeight: "24px",
                    textTransform:'capitalize'
                  }}>{` ${activityType === 'distance' ?DistanceUnit === "KILOMETER"?'Km' :'Miles' : activityType}`}</span>

              </div>
            </div>
          </div>

          {/* Team Contribution */}

          <div
            style={{
              background:
                "#69c2ff",
              height: "50px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <span
                style={{ color: "white", fontSize: "18px", lineHeight: "24px",fontFamily:'rubik-medium', textAlign:'center',fontWeight:'500' }}
              >
              Team Members & Contributions
              </span>
            </div>
          </div>

          {/* Team Members */}

          <div style={{ padding: "30px", width:'100%' }}>
            {!isNull(team_users) && team_users.length > 0 &&  team_users.map((member, index) => (
              <React.Fragment key={index}>
                <div style={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                  <div style={{display:'flex',alignItems:'center', width:'7%'}}>
                    <span style={{color:'#005C87', fontSize:'16px', lineHeight:'20px', fontFamily:"rubik-medium"}}>{`${index + 1}${rankingDashboard(
                      index
                    )}`}</span>
                  </div>
                  <div style={{margin:'0px 15px',position:'relative'}}>
                    <img style={{borderRadius: "6px"}} src={ImageUrl + "/" + member.profile_image || 'profile-Image/default.png'} width={60} height={60}/>
                  </div>
                  <div>
                    <div>
                      <span style={{color: "#005C87", fontSize: "16px",lineHeight: "24px"}}>{member.fname + " " + member.lname}</span>
                      {member.is_captain === 1 && <span  style={{color: "#69c2ff", fontSize: "16px",lineHeight: "24px"}}>{' (Captain)'}</span>}
                    </div>
                    <div>
                      <span  style={{color: "#005C87", fontSize: "16px",lineHeight: "24px", fontFamily:"rubik-medium"}}>{isNull(member[activityType]) ? 0 :activityType === 'distance'?DistanceUnit === "KILOMETER"?`${commaSeperator(convertMilesToKm(member[activityType]))} ` :`${commaSeperator(member[activityType])} `: `${commaSeperator(member[activityType])} `}</span>
                      <span style={{textTransform:'capitalize', color: "#005C87"}}> {` ${activityType === 'distance' ?DistanceUnit === "KILOMETER"?'Km' :'Miles' : activityType}`}</span>
                    </div>
                  </div>
                  <div style={{display:"flex", margin:"auto", marginRight:"0px"}}>
                    {member.is_captain === 1 && (<img style={{width: "24px",height: "24px"}} src="/public/images/captain-band.png"/>)}
                  </div>
                </div>
                {index + 1 != team_users.length && <Border style={{ margin: "10px 0px" }} width="100%" background="#005C874D"/>}
              </React.Fragment>
            ))}
          </div>
          <div style={{padding:'5px', borderRadius:'10px', position:'sticky',bottom:0}}>

          </div>
        </Modal.Body>
      </SeeAllStyledModal>
    );
  };

  return <React.Fragment>{SeeAllModal()}</React.Fragment>;
};

TeamInfo.propTypes = {
  showTeamInfo: PropTypes.bool,
  hideTeamInfoModal: PropTypes.func,
  t: PropTypes.func,
  selectedTeamDetails: PropTypes.object,
  team_users:PropTypes.array,
  activityType:PropTypes.string,
  DistanceUnit:PropTypes.string,
  teamPosition:PropTypes.number
};
export default TeamInfo;

/* eslint-disable camelcase*/
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import Waiting from '../../Waiting';
import { commaSeperator, convertDateInTimezone, GetSortedData, rankingDashboard, convertMilesToKm } from '../../../utils/methods';
import { LeaderBoardSection } from '../ChallengeLeaderboardV2/styles';
import { leaderboardPageLimit } from '../../../utils/constants';
import { ContainerWrapper, PositionCards } from './styles';
import { ImageUrl } from '../../../utils/constants';
import isNull from 'lodash/isNull';
import { ParticipantCount } from '../MyTaskTab/styles';
import { withTranslation } from 'react-i18next';
import TeamInfo from './teamInfo';

class TeamChallengeLeaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatedImage: false,
      selectedOption: 'all',
      selectedActivityType: 'Steps',
      pageNumber: 1,
      isLoading: false,
      userData: undefined,
      showTeamInfo: false,
      userIndex: 0,
      teamName: '',
      selectedTeamPosition:0,
      selectedTeamDetails:{},
    }
  }

  componentDidMount() {
    const { selectedOption, pageNumber } = this.state;
    const { location: { pathname }, fetchChallengeCompetitors, challenge, fetchTeamChallenge } = this.props;
    const StringArray = pathname.split('/');
    fetchChallengeCompetitors(StringArray[(StringArray.length - 1)], challenge.event_type, leaderboardPageLimit, pageNumber, selectedOption, () => { this.changeLoading() });
    if (challenge.challenge_type === 'survivor' && challenge.challenge_mode == 'Team') {
      fetchTeamChallenge(StringArray[(StringArray.length - 1)], challenge.event_type);
    }
    this.checkBase64(this.props.profileImage);

  }

  hideTeamInfoModal = () => {
    this.setState({ showTeamInfo: false })
  }

  changeLoading(bool) {
    this.setState({ isLoading: bool })
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.challenge !== nextProps.challenge) {
      const { challenge } = nextProps;
      this.props.fetchChallengeCompetitors(challenge.id, challenge.event_type, leaderboardPageLimit, this.state.pageNumber, this.state.selectedOption, () => { this.changeLoading() });
      if (challenge.challenge_type === 'survivor' && challenge.challenge_mode === 'Team') {
        nextProps.fetchTeamChallenge(challenge.id, challenge.event_type);
      }
    }

    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }

  onScroll = (e) => {
    const { pageNumber } = this.state;
    const { location: { pathname }, challengeCompetitors, competitorsCount, challenge } = this.props;
    const StringArray = pathname.split('/');
    const bottom = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) === Math.round(e.target.clientHeight);
    if (bottom && challengeCompetitors.length < competitorsCount) {
      this.setState({
        pageNumber: pageNumber + 1,
        isLoading: true
      }, () => {
        this.props.fetchChallengeCompetitors(StringArray[(StringArray.length - 1)], challenge.event_type, leaderboardPageLimit, this.state.pageNumber, this.state.selectedOption, () => { this.changeLoading() });
      });
    }
  };

  teamInfoArrow = (e, index)=>{
    this.setState({selectedTeamDetails:e, selectedTeamPosition:Number(index)},()=>{
      this.setState({showTeamInfo:true});
    });
  }

  render() {
    const { challenge, networkError, userId, t, challengeAttendees } = this.props;
    if ((!(this.props.challengeCompetitors) || !userId) && !networkError  ) {
      return <Waiting />
    }
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    let challengeCompetitors = [];
    if (challenge.challenge_status !== 'over' || valid) {
      challengeCompetitors = this.props.challengeCompetitors;
    }
    else if (this.props.challengeCompetitors && this.props.challengeCompetitors.length > 0) {
      challengeCompetitors = this.props.challengeCompetitors;
      challengeCompetitors = this.props.challengeCompetitors.sort(GetSortedData('challenge_total'));
    }
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    const ChallengeTypes = challenge.event_type;
    const activityType = ChallengeTypes === 'Total Distance' ? 'distance' : ChallengeTypes === 'Total Steps' ? 'steps' : 'calories';
    const DistanceUnit = localStorage.getItem('DISTANCE');
    if (_.isNull(challengeCompetitors) || _.isUndefined(challengeCompetitors)) {
      return <Waiting />
    }

    return (
      <React.Fragment>
        {challenge && (challenge.challenge_status !== 'over') && (today < startDay) ?
          <ParticipantCount >
            <div>{t("Total Participants")}</div>
            <div>{challengeAttendees && challengeAttendees.length}</div>
          </ParticipantCount > :
          (challenge.challenge_status == 'joined'||challenge.challenge_status == 'over')?<ContainerWrapper>
            <div className='headingMain'>
              <div className='leader'>{this.props.t("Top Leaders")}</div>
              <div className='participantsCount'>{challengeCompetitors && challengeCompetitors.length} {this.props.t("Teams")}</div>
            </div>
            <LeaderBoardSection onScroll={this.onScroll} marginTop={"15px"}>
              {!_.isNull(challengeCompetitors) && !_.isUndefined(challengeCompetitors) && !_.isEmpty(challengeCompetitors) && challengeCompetitors.length > 0 ? challengeCompetitors.map((data, index) => (
                <PositionCards key={index} border={index===0?"#76AB78":index===1?"#FFBF73":index===2?"#F4AAA9":0}>
                  <div>
                    <div>
                      {index===0&&<div className="topPositionCard">
                        <img src={'/public/images/behaviorChallenges/winner.png'} alt={'winner'} />
                      </div>}
                      <img style={{backgroundColor:'white'}} src={data.logo ? `${ImageUrl}/${data.logo}`: '/public/images/defaultProfileImg.png'} alt={data.logo} />
                    </div>
                    <div>
                      <div>{`${index+1}${rankingDashboard(index)}`}</div>
                      <div>{data.name || "-"}</div>
                    </div>
                  </div>
                  <div style={{alignItems:'center', display:'flex'}}>
                    <div style={{alignItems:'center', display:'flex', textTransform:'capitalize'}}>{isNull(data[`team_contribution`]) ? 0 :activityType === 'distance'?DistanceUnit === "KILOMETER"?`${commaSeperator(convertMilesToKm(data[`team_contribution`]))} ` :`${commaSeperator(data[`team_contribution`])} `: `${commaSeperator(data[`team_contribution`])} `}</div>&nbsp;
                    <div style={{marginRight:'35px',textTransform:'capitalize'}}>{` ${activityType === 'distance' ?DistanceUnit === "KILOMETER"?'Km' :'Miles' : this.props.t(activityType)}`}</div>
                    <span style={{cursor:'pointer'}} onClick={()=>this.teamInfoArrow(challengeCompetitors[index], index)}>
                      <img src={ImageUrl+'/ChallengeDetailsScreen/teamsArrow.png'} height={"70px"} width="30px"></img>
                    </span>
                  </div>
                </PositionCards>)) : null}
            </LeaderBoardSection>
          </ContainerWrapper>:
            <ParticipantCount >
              <div>{t("Total Participants")}</div>
              <div>{challengeAttendees && challengeAttendees.length}</div>
            </ParticipantCount >
        }

        {this.state.showTeamInfo && <TeamInfo selectedTeamDetails={this.state.selectedTeamDetails} activityType={activityType} DistanceUnit={DistanceUnit} showTeamInfo={this.state.showTeamInfo} hideTeamInfoModal={this.hideTeamInfoModal} teamPosition={this.state.selectedTeamPosition}/> }
      </React.Fragment>
    )
  }
}

TeamChallengeLeaderboard.propTypes = {
  challenge: PropTypes.object.isRequired,
  fetchChallengeCompetitors: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  challengeCompetitors: PropTypes.array,
  networkError: PropTypes.string,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  fetchTeamChallenge: PropTypes.func.isRequired,
  teamChallenge: PropTypes.array,
  competitorsCount: PropTypes.number,
  t: PropTypes.func,
  challengeAttendees: PropTypes.array
};

const mapStateToProps = (state) => ({
  challengeCompetitors: state.challenges.challengeCompetitors,
  teamChallenge: state.challenges.teamChallenge,
  competitorsCount: state.challenges.competitorsCount,
  challengeAttendees: state.challenges.challengeAttendees,
});

export default connect(mapStateToProps)(withTranslation()(TeamChallengeLeaderboard));
